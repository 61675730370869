<template>
  <div
    class="home"
    v-bind:class="$vuetify.breakpoint.smAndDown ? 'mx-2' : ''"
    style="margin-bottom: 200px; margin-top: 150px"
  >
    <!-- <v-row align="center" justify="center" class="mt-12 text-center"> -->
    <v-row justify="center" class="mt-12 text-center white--text">
      <v-col cols="12" class="d-flex justify-center">
        <v-img
          :src="require('@/assets/404.svg')"
          alt="Page not found image"
          class=""
          max-width="300"
        ></v-img>
      </v-col>
      <v-col cols=12 class="text-center">
          <h3> La pagina che stai cercando non esiste</h3>
      </v-col>
      <v-col cols=12>
          <v-btn text color="white" :to="{name:'Home'}">Torna alla home</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>